import type { ActionFunction, LoaderFunction } from 'remix';
import { Form, json, redirect, useActionData } from 'remix';
import Button from '~/components/Button';
import Input from '~/components/Input';
import { createApi } from '~/util/api.server';

export const loader: LoaderFunction = async ({ request }) => {
  const api = createApi(request);

  if (await api.isLoggedIn()) {
    throw redirect('/');
  }

  return json({});
};

export const action: ActionFunction = async ({ request }) => {
  const api = createApi(request);
  const formData = await request.formData();
  return api.login({
    username: String(formData.get('username')),
    password: String(formData.get('password')),
  });
};

export default function Login() {
  const actionData = useActionData();

  return (
    <div className="flex w-full flex-col items-center justify-center">
      <div className="w-96 rounded-md bg-white p-6 shadow-md">
        <h2 className="mb-6 text-2xl">Anmelden</h2>
        <Form method="post">
          <div className="mb-6">
            <Input
              type="text"
              name="username"
              id="username"
              label="Benutzername"
              autoComplete="username"
              className="mb-4"
            />
            <Input
              type="password"
              name="password"
              id="password"
              label="Passwort"
              autoComplete="current-password"
            />
          </div>

          {actionData?.errors ? (
            <div className="mb-6 text-sm">
              {actionData.errors.map((error: string) => (
                <p className="mb-4 text-red-500" key={error}>
                  {error}
                </p>
              ))}
            </div>
          ) : null}

          <Button type="submit">Login</Button>
        </Form>
      </div>
      <a
        className="mt-2 p-2 text-center text-xs hover:underline"
        href="/forgot-password"
      >
        Passwort vergessen?
      </a>
    </div>
  );
}
